import React, { Component } from "react";
import { texts } from '../../../assets/texts'

class About extends Component {
    render() {
        let title = 'About',
            description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,';
        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src={texts.about.profilePicture} alt="About Images" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{texts.about.aboutTitle}</h2>

                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h className="title">A propos du batisseur?</h>
                                                <p>{texts.about.aboutMe}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;