import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import texts from "../config.json"

import TextLoop from "react-text-loop";
import { Background } from "react-parallax";


const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]


class Home extends Component {
    render() {
        return (
            <Fragment>
                <Helmet pageTitle="Batisseur de coeur" />
                {/* Start Slider Area   */}
                <div className="slider-wrapper" >
                    <HeaderThree homeLink="/" logo="symbol-dark" color="color-white" />
                    {/* Start Slider Area   */}
                    <div id="home" className="fix" >
                        <div className="slider-wrapper" >
                            {/* Start Single Slide */}
                            {SlideList.map((value, index) => (
                                <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image " style={{ backgroundImage: "url(" + texts.home.image + ")" }} key={index} >
                                    <div className="container"  >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`} >
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    <h1 className="title" style={{ color: "white" }}>{texts.home.headerTitle}< br />
                                                        <TextLoop>
                                                            <span> {texts.home.rollingOne}.</span>
                                                            <span> {texts.home.rollingTwo}.</span>
                                                            <span> {texts.home.rollingThree}.</span>
                                                        </TextLoop>{"  "}
                                                    </h1>
                                                    <h2 style={{ color: "#d9d9d9" }}>{texts.home.headerLocalization}</h2>
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* End Single Slide */}
                        </div>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <About />
                </div>
                {/* End About Area */}

                <div className="service-area ptb--80  bg_image bg_image--3">
                    <div className="container">
                        <ServiceTwo />
                    </div>
                </div>

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}


                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">{texts.funFacts.title}</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>

                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </Fragment >
        )
    }
}
export default Home;