import React, { Component } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import { useParams } from "react-router-dom";
import { projects } from "../../../assets/projects"
import { texts } from "../../../assets/texts"


class Portfolio extends Component {
    render() {
        let title = 'Our Works',
            description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.';
        return (
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{texts.chantier.CHANTIER_INTRO}</h2>
                                    <p>{texts.chantier.CHANTIER_DESCRIPTION}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {projects.map((value, index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}>
                                            <img src={value.vThumb} />
                                        </div>
                                        <div className={`bg-blr-image ${value.image}`}></div>

                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4><a href="/portfolio-details">{value.title}</a></h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href={"/portfolio-details?projectId=" + value.projectId}>{texts.chantier.DISCOVER_MORE}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;