import DAVID from "../assets/media/david_batisseur.jpg"

export const texts = {
    home: {
        headerTitle: "Le batisseur",
        rollingOne: "Passion",
        rollingTwo: "Experience",
        rollingThree: "Brouette",
        headerLocalization: "Secteur Clisson / Sud Loire.",
        image: "https://musing-curie-e950b8.netlify.app/images/header3.jpg",
    },

    about: {
        profilePicture: DAVID,
        aboutTitle: "Notre passion, la maçonnerie traditionelle",
        aboutSubTitle: "Réalisation de projets de maconnerie traditionnelle en loire atlantique secteur nantes sud / clissons.",
        aboutMe: "Je m'appelle David Messe, maçon passionné de vielles pierres, j'ai fondé mon entreprise de maçonnerie traditionnelle en 2012. Depuis 2018, j'interviens également comme expert en batiment pour huissiers et assurances sur des projets de réhabitilations et restorations",
    },
    services: {
        service1: "Rehabilitation",
        service2: "Restauration",
        service3: "Renovation",
        service4: "Création",

    },
    chantier: {
        CHANTIER_INTRO: "Nos chantiers",
        CHANTIER_DESCRIPTION: "Nos chantiers du moments",
        DISCOVER_MORE: "Découvrir le projet"
    },
    funFacts: {
        title: "Chiffres clés",
        data: [{
            FUN_FACT_NUMBER: 237,
            FUN_FACT_TEXT: "Nombre de clients satifisfait depuis la création de la sociiété il y a 12 ans",
        },
        {
            FUN_FACT_NUMBER: 8,
            FUN_FACT_TEXT: "L'age de Capucine, ma brouette. Je privilegie le bon matériel que j'entretiens avec soins",
        },
        {
            FUN_FACT_NUMBER: 0,
            FUN_FACT_TEXT: "Le nombre de parpaing posé cette année, je préfére de loin travailler la pierre !"
        }]
    },
    Projects: {
        PROJECT_TYPE: "Type",
        PROJECT_YEAR: "Année",
        PROJECT_LOCATION: "Localisation",
    }
}


