export const projects = [
    {
        projectId: 1,
        image: 'image-2',
        category: 'Renovation',
        title: ' Creation ouverture mur de grange',
        vThumb: "https://musing-curie-e950b8.netlify.app/projets/P1/vthumb.jpg",
        hero: "https://musing-curie-e950b8.netlify.app/projets/P1/hero.jpg",
        projectPhotos: [
            "https://musing-curie-e950b8.netlify.app/projets/P1/P1_1.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P1/P1_2.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P1/P1_3.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P1/P1_4.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P1/P1_6.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P1/P1_7.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P1/P1_8.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P1/P1_9.jpg"]
    },
    {
        projectId: 2,
        image: 'image-1',
        category: 'Création',
        title: " Voute en pierre dans un corps de ferme",
        vThumb: "https://musing-curie-e950b8.netlify.app/projets/P2/vthumb.jpg",
        hero: "https://musing-curie-e950b8.netlify.app/projets/P2/hero.jpg",
        projectPhotos: [
            "https://musing-curie-e950b8.netlify.app/projets/P2/P2_1.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P2/P2_2.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P2/P2_3.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P2/P2_4.jpg",
        ]

    },
    {
        projectId: 3,
        image: 'image-1',
        category: 'Création',
        title: ' Scuplure sur enduit imitation gré taillée',
        vThumb: "https://musing-curie-e950b8.netlify.app/projets/P3/vthumb.jpg",
        hero: "https://musing-curie-e950b8.netlify.app/projets/P3/hero.jpg",
        projectPhotos: [
            "https://musing-curie-e950b8.netlify.app/projets/P3/P3_1.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P3/P3_2.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P3/P3_3.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P3/P3_4.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P3/P3_5.jpg",
        ]
    },
    {
        projectId: 4,
        image: 'image-1',
        category: 'Création',
        title: ' Passerelle en beton',
        vThumb: "https://musing-curie-e950b8.netlify.app/projets/P4/vthumb.jpg",
        hero: "https://musing-curie-e950b8.netlify.app/projets/P4/hero.jpg",
        projectPhotos: [
            "https://musing-curie-e950b8.netlify.app/projets/P4/P4_1.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P4/P4_2.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P4/P4_3.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P4/P4_4.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P4/P4_5.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P4/P4_6.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P4/P4_7.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P4/P4_8.jpg",
        ]
    },
    {
        projectId: 5,
        image: 'image-1',
        category: 'Renovation',
        title: ' Grande ouverture économique',
        vThumb: "https://musing-curie-e950b8.netlify.app/projets/P5/vthumb.jpg",
        hero: "https://musing-curie-e950b8.netlify.app/projets/P5/hero.jpg",
        projectPhotos: [
            "https://musing-curie-e950b8.netlify.app/projets/P5/P5_1.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P5/P5_2.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P5/P5_3.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P5/P5_4.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P5/P5_5.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P5/P5_6.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P5/P5_7.jpg",
            "https://musing-curie-e950b8.netlify.app/projets/P5/P5_8.jpg",
        ]
    },
    {
        projectId: 6,
        image: 'image-1',
        category: 'Restoration',
        title: " Porte d'entrée encadrement pierres taillées",
        vThumb: "https://musing-curie-e950b8.netlify.app/projets/P6/vthumb.jpg"
    },
    {
        projectId: 7,
        image: 'image-1',
        category: 'Réhabilitation',
        title: " Ouverture sur l'intégralité d'une facade",
        vThumb: "https://musing-curie-e950b8.netlify.app/projets/P7/vthumb.jpg"
    },
]
